import type {ScopeContext, SeverityLevel} from '@sentry/types'
import {logError} from './util/debug-log'

/** @deprecated */
type PartialScopeContext = Partial<Omit<ScopeContext, 'level'>>

/** @deprecated */
export function logErrorMessage(message: string, captureContext: PartialScopeContext = {}) {
    if(typeof Sentry !== 'undefined') {
        Sentry.captureMessage(message, {
            level: Sentry.Severity.Error,
            ...captureContext,
            extra: {
                logger: 'sentry_logErrorMessage',
                ...captureContext.extra,
            }
        })
    }
    if(__DEV__) {
        console.error(message, captureContext)
    }
}

/** @deprecated */
export function logWarningMessage(message: string, captureContext: PartialScopeContext = {}) {
    if(typeof Sentry !== 'undefined') {
        Sentry.captureMessage(message, {
            level: Sentry.Severity.Warning,
            ...captureContext,
            extra: {
                logger: 'sentry_logWarningMessage',
                ...captureContext.extra,
            }
        })
    }
    if(__DEV__) {
        console.warn(message, captureContext)
    }
}

export type SentryContext = Partial<Omit<ScopeContext, 'level'> & {
    level: SeverityLevel | ScopeContext['level']
}>

export function captureSentryError(message: string, error?: Error | object | unknown, extra?: Record<string, any> | null, guid?: string, scopeContext?: SentryContext | null) {

    let sentryId = ''
    if(typeof Sentry !== 'undefined') {
        const captureContext = {
            level: Sentry.Severity.Error as any,
            ...scopeContext,
            extra: {
                ...scopeContext?.extra,
                ...extra,
            } as Record<string, any>,
            fingerprint: [
                ...(guid ?? []),
                ...(scopeContext?.fingerprint || []),
            ],
            contexts: {
                ...scopeContext?.contexts,
                app: {
                    version: window.env?.APP_VERSION,
                    env: window.env?.APP_ENV,
                    logger: 'captureSentryError',
                },
                user: window.user ?? {},
                company: window.company ?? {},
            }
        } satisfies Partial<ScopeContext>
        if(error instanceof Error) {
            captureContext.extra['message'] = message
            sentryId = Sentry.captureException(error, captureContext)
        } else {
            if(error != null) {
                captureContext.extra['error'] = error
            }
            sentryId = Sentry.captureMessage(message, captureContext)
        }
    }
    if(__DEV__) {
        logError(`⚠️ ${message} ⚠️`,
            error ?? '《no error info》',
            extra ?? '《no extra》',
            scopeContext ?? '《no scope context》',
            `https://kymark.sentry.io/issues/?project=51607&query=${sentryId}`)
    }
}
