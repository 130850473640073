import type {DangerousHtml} from './helpers/react-helpers'

import $ from './libs/jquery'
import _ from './libs/lodash'
import '../stylesheets/notify.less'
import type {ReactElement} from 'react';
import React from 'react'
import RDS from 'react-dom/server'

type NotifyClassNames = 'success'|'error'|'invalid'|'info'|'warning'

// https://stackoverflow.com/questions/66946335/ts2339-property-not-recognized-on-alternating-type
type NotifyOptions = {
    className: NotifyClassNames
    timeout?: null | number
    text?: string|ReactElement
    html?: string
}

export function notifySuccess(message: string|ReactElement) {
    return notify({
        text: message,
        className: 'success'
    })
}

export function notifyError(message: string|ReactElement) {
    return notify({
        text: message,
        className: 'error'
    })
}

export function notifyWarning(message: string|ReactElement) {
    return notify({
        text: message,
        className: 'warning'
    })
}

export type NotifyMessageType = string|DangerousHtml

export function notifyMessage(className: NotifyClassNames, message: NotifyMessageType) {
    if (typeof message === 'string') {
        notify({
            text: message,
            className,
        })
    } else if (message.__html) {
        notify({
            html: message.__html,
            className,
        })
    } else {
        throw new Error("Unexpected message type")
    }
}

export default function notify(options: NotifyOptions) {
    let $notifyContainer = $('#notify-container');
    if (!$notifyContainer.length) {
        $notifyContainer = $('<ul id="notify-container">').appendTo('body');
    }
    let content;
    let strlen;
    let timeout;

    if (options.text) {
        if (React.isValidElement(options.text)) {
            content = RDS.renderToStaticMarkup(options.text);
        } else {
            content = _.escape(options.text);
        }
    } else if (options.html) {
        content = options.html;
    } else {
        throw new Error("Missing content");
    }
    const $msg = $('<div class="notify-message">');
    if (options.className) $msg.addClass(options.className);
    $msg.html(content);
    const $item = $('<li class="notify-item">').css({opacity: 0}).append($msg).prependTo($notifyContainer);
    const fadeIn = function () {
        $item.transition({
            opacity: 1,
            duration: 400,
            complete: function () {
                $item.removeAttr('style');
            }
        });
    };
    if ($notifyContainer.children().length) {
        const height = $item.height();
        $item.css({height: 0}).transition({
            height: height,
            duration: 200,
            complete: fadeIn
        });
    } else {
        fadeIn();
    }
    const fadeOut = _.once(function (duration: any) {
        $item.transition({
            opacity: 0,
            duration: duration || 400,
            complete: function () {
                $item.remove();
            }
        });
    });

    let mouseMove = false;
    $msg.on('mousemove', function () {
        mouseMove = true;
    }).on('mousedown', function () {
        mouseMove = false;
    }).on('mouseup', function () {
        if (!mouseMove) {
            fadeOut(200);
        }
    });

    if (options.timeout === undefined || options.timeout === null) {
        if (typeof options.text === 'string') {
            strlen = options.text.length;
        } else if (options.html) {
            strlen = stripTags(options.html).length;
        }
        timeout = 5000 + strlen * 75;
    } else {
        timeout = options.timeout;
    }

    if (timeout) {
        setTimeout(function () {
            if ($msg.is(':hover')) {
                $msg.hoverDelay(null, fadeOut.bind(null, 800));
            } else {
                fadeOut(800);
            }
        }, timeout);
    }

    return {
        close: _.bind(fadeOut, null, 200),
    }
}

function stripTags(str: any) {
    return str ? str.replace(/<[a-z][^>]*>/ig, '') : '';
}
